import axios from 'axios';
// 下载文件工具函数
export const downloadFile = async (
  url: string,
  filename: string,
  callback: any
) => {
  try {
    let sourceUrl = '';
    // console.log(url, "url");
    const sourceArr = sourceUrl.split('?');
    const suffixArr = sourceArr[0].split('.');
    const suffix = sourceArr[0].split('.')[suffixArr.length - 1];
    console.log('url:', url);

    const res = await axios.get(url + '&' + Math.random().toString(), {
      responseType: 'blob',
      onDownloadProgress: function (progressEvent: any) {
        // axios封装的原生获取下载进度的事件，该回调参数progressEvent中包含下载文件的总进度以及当前进度
        if (progressEvent.lengthComputable) {
          // 属性lengthComputable主要表明总共需要完成的工作量和已经完成的工作是否可以被测量
          // 如果lengthComputable为false，就获取不到progressEvent.total和progressEvent.loaded
          let downLoadProgress = (
            (progressEvent.loaded / progressEvent.total) *
            100
          ).toFixed(0); // 实时获取最新下载进度
          callback(downLoadProgress);
        }
      },
      //   return request.get(url, { responseType: "blob" });
    });
    console.log('res:', res);

    const data = res.data;
    const blob = new Blob([data], {
      type: 'application/octet-stream',
    });
    const blobURL = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = blobURL;
    // link.download = new Date().getTime() + "." + suffix;
    link.download = filename || new Date().getTime() + '.' + suffix;
    link.click();
    window.URL.revokeObjectURL(blobURL);
  } catch (err) {
    console.log(err);
  }
};

export const downloadFileMore = async (url: string, callback: any) => {
  try {
    let sourceUrl = url;
    // console.log(url, "url");
    const sourceArr = sourceUrl.split('?');
    const suffixArr = sourceArr[0].split('.');
    const suffix = sourceArr[0].split('.')[suffixArr.length - 1];

    const res = await axios.get(url + '&' + Math.random().toString(), {
      responseType: 'blob',
      onDownloadProgress: function (progressEvent: any) {
        // axios封装的原生获取下载进度的事件，该回调参数progressEvent中包含下载文件的总进度以及当前进度
        if (progressEvent.lengthComputable) {
          // 属性lengthComputable主要表明总共需要完成的工作量和已经完成的工作是否可以被测量
          // 如果lengthComputable为false，就获取不到progressEvent.total和progressEvent.loaded
          let downLoadProgress = (
            (progressEvent.loaded / progressEvent.total) *
            100
          ).toFixed(0); // 实时获取最新下载进度
          callback(downLoadProgress);
        }
      },
      //   return request.get(url, { responseType: "blob" });
    });
    // console.log("res:", res);

    const data = res.data;
    // const blob = new Blob([data], {
    //   type: 'application/octet-stream',
    // });
    // const blobURL = window.URL.createObjectURL(blob);
    return data;
  } catch (err) {
    console.log(err);
  }
};

export const downloadDemoFile = async (
  url: string,
  filename: string,
  callback: any
) => {
  try {
    let sourceUrl = '';
    // console.log(url, "url");
    const sourceArr = sourceUrl.split('?');
    const suffixArr = sourceArr[0].split('.');
    const suffix = sourceArr[0].split('.')[suffixArr.length - 1];
    console.log('url:', url);

    // const res = await axios.get(url + '?' + Math.random().toString(), {
    const res = await axios.get(url + '?' + Math.random().toString(), {
      responseType: 'blob',
      onDownloadProgress: function (progressEvent: any) {
        // axios封装的原生获取下载进度的事件，该回调参数progressEvent中包含下载文件的总进度以及当前进度
        if (progressEvent.lengthComputable) {
          // 属性lengthComputable主要表明总共需要完成的工作量和已经完成的工作是否可以被测量
          // 如果lengthComputable为false，就获取不到progressEvent.total和progressEvent.loaded
          let downLoadProgress = (
            (progressEvent.loaded / progressEvent.total) *
            100
          ).toFixed(0); // 实时获取最新下载进度
          callback(downLoadProgress);
        }
      },
      //   return request.get(url, { responseType: "blob" });
    });
    console.log('res:', res);

    const data = res.data;
    const blob = new Blob([data], {
      type: 'application/octet-stream',
    });
    const blobURL = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = blobURL;
    // link.download = new Date().getTime() + "." + suffix;
    link.download = filename || new Date().getTime() + '.' + suffix;
    link.click();
    window.URL.revokeObjectURL(blobURL);
  } catch (err) {
    console.log(err);
  }
};

export const downloadDemoFileMore = async (url: string, callback: any) => {
  try {
    let sourceUrl = url;
    // console.log(url, "url");
    const sourceArr = sourceUrl.split('?');
    const suffixArr = sourceArr[0].split('.');
    const suffix = sourceArr[0].split('.')[suffixArr.length - 1];

    const res = await axios.get(url + '?' + Math.random().toString(), {
      responseType: 'blob',
      onDownloadProgress: function (progressEvent: any) {
        // axios封装的原生获取下载进度的事件，该回调参数progressEvent中包含下载文件的总进度以及当前进度
        if (progressEvent.lengthComputable) {
          // 属性lengthComputable主要表明总共需要完成的工作量和已经完成的工作是否可以被测量
          // 如果lengthComputable为false，就获取不到progressEvent.total和progressEvent.loaded
          let downLoadProgress = (
            (progressEvent.loaded / progressEvent.total) *
            100
          ).toFixed(0); // 实时获取最新下载进度
          callback(downLoadProgress);
        }
      },
      //   return request.get(url, { responseType: "blob" });
    });
    // console.log("res:", res);

    const data = res.data;
    // const blob = new Blob([data], {
    //   type: 'application/octet-stream',
    // });
    // const blobURL = window.URL.createObjectURL(blob);
    return data;
  } catch (err) {
    console.log(err);
  }
};
export const downloadVideo = async (
  url: string,
  filename: string,
  callback: any
) => {
  try {
    return new Promise((res, rej) => {
      console.log('filename1111:', filename);
      const link = document.createElement('a');
      link.href = url;
      // link.download = new Date().getTime() + "." + suffix;
      link.download = filename;
      link.click();
      res(true);
    });
  } catch (err) {
    console.log(err);
  }
};
