import axios from 'axios';
import store from '@/store';
import { service } from '@/utils/request';

const baseURL = 'https://integrated.afirstsoft.cn/'; // 目标测试地址

// 获取设备列表
export const getVideoApi = (data: any) => {
  return service({
    url: '/v1/videos',
    method: 'get',
    params: data,
  });
};
