import { createFFmpeg, fetchFile, FFmpeg } from '@ffmpeg/ffmpeg';
import { v4 as uuidv4 } from 'uuid';
import { useStore } from 'vuex';
import emitter from '@/utils/eventBus';

// 全局创建转码器实例
let ffmpegInstance: any;
// 转码
export const videoTranscodeHandler = async (file: any) => {
  return new Promise<any>((resolve, reject) => {
    if (!ffmpegInstance) {
      ffmpegInstance = createFFmpeg({
        corePath: window.origin + '/ffmpeg-core.js',
        log: false,
      });
    } else {
      console.log('ffmpegInstance已存在');
    }
    console.log('file:', file);

    let realFileName = file;
    // let realFileName = file.substr(0, file.lastIndexOf('?'));
    // let realFileName = uuidv4();
    console.log('开始转码：', realFileName);
    console.time('videoTranscode');
    const uuidFileName = uuidv4();
    // const uuidFileName = 'uuidv4';
    videoTranscodeFunc(ffmpegInstance, file, uuidFileName)
      .then(() => {
        let name = uuidFileName;
        // 转码进度
        let curProgress: number = 0; // 进度取值不稳定，作为对比
        let startTranscode = false;
        let progressCounter = 0;
        console.log('进到这里了？:', name);
        const store = useStore();

        ffmpegInstance.setProgress(({ ratio }: any) => {
          console.log('ratio', ratio);
          // 开始转码时跳转到进度页，只执行一次
          // 看是否进度也，没有进度页还是保持在上传页中
          if (!startTranscode) {
            startTranscode = true;
          }
          // ratio is a float number between 0 to 1.
          // ratio可能小于0或者大于1，需要处理
          if (ratio > 0) {
            let progress = parseInt(ratio * 100 + ''); //转为两位数
            console.log('curProgress', curProgress);

            if (progress < 100) {
              if (progressCounter === 0 && progress > 90) return; // 规避转码异常
              curProgress = Math.max(curProgress, progress); //取相对更大的值，避免进度倒着走
              curProgress = curProgress < 1 ? 1 : curProgress; // 大文件转码初始进度0.00X，出现长时间为0的情况优化
              //   fileStore.$patch({
              //     transcodeProgress: curProgress,
              //   });
              let dePro = curProgress;
              // store.commit('setCurProgress', dePro);
              console.log('curProgress1:', dePro);
              emitter.emit('curProgressFun', dePro);
              progressCounter++;
            }
          }
        });

        // 通过文件格式校验的，在解析时也加一层拦截，检查异常文件，报错中断
        ffmpegInstance.setLogger(({ type, message }: any) => {
          // 异常情况判断
          const invalidMessage: Array<string> = [
            'Conversion failed!',
            'pthread sent an error!',
          ];
          let invalid = false;
          if (message instanceof Array) {
            invalid = invalidMessage.some((item) => message.includes(item));
          }

          if (type === 'fferr' && invalid) {
            // 解析后仍无法操作的情况
            console.log('fferr：在这里gg了？');
            resolve({
              type: 'fileTranscodeFailure',
              result: false,
            });
          }

          // 转码结束处理
          if (type === 'ffout') {
            try {
              console.log('是这里出问题了吗老弟');
              console.log('是这里出问题了吗老弟：', `${name}.mp4`);
              const data = ffmpegInstance.FS('readFile', `${name}.mp4`);
              console.log('转码结束');
              console.timeEnd('videoTranscode');
              let videoUrl = '';
              let blob = new Blob([data.buffer], { type: 'video/mp4' });
              videoUrl = URL.createObjectURL(blob);

              // 将转换完成的文件进行重建
              // 这里使用realFileName，即文件真实名称，用于下载
              var result = new File([data.buffer], `${realFileName}.mp4`, {
                type: 'video/mp4',
                lastModified: Date.now(),
              });
              console.log('result:::::', result, videoUrl);
              resolve({
                type: 'fileTranscodeSuccess',
                result: true,
                videoUrl,
              });
              ffmpegInstance.FS('unlink', `${name}.mp4`); // 释放内存中的文件
            } catch (err: any) {
              console.log('转码失败', err);
              //   if (!device.mobile()) {
              setTimeout(() => {
                try {
                  ffmpegInstance.exit();
                } catch (error) {
                  ffmpegInstance = null;
                } finally {
                  ffmpegInstance = null;
                }
              }, 1000);
              //   }
              //   // 解析后仍无法操作的情况
              //   let size =
              //     (Math.round(fileSize / 1024 / 1024) == 0
              //       ? 1
              //       : Math.round(fileSize / 1024 / 1024)) + 'm';
              //   uploadGaEvent(
              //     'oss_upload_video_fail',
              //     `FileNotSupport_${fileExt}_${size}`
              //   );
              // throw new Error(err.toString());

              resolve({
                type: 'FILETRANSCODEFAILURE',
                result: false,
              });
            }
          }
        });
      })
      .catch((error: any) => {
        console.log('转码错误', error);
        // if (!device.mobile()) {
        try {
          ffmpegInstance.exit();
        } catch (error) {
          ffmpegInstance = null;
        } finally {
          ffmpegInstance = null;
        }
        // }
        // fileStore.$patch({
        //   needTranscode: false,
        // });
        // let size =
        //   (Math.round(fileSize / 1024 / 1024) == 0
        //     ? 1
        //     : Math.round(fileSize / 1024 / 1024)) + 'm';
        // uploadGaEvent(
        //   'oss_upload_video_fail',
        //   `FileNotSupport_${fileExt}_${size}`
        // );
        // throw new Error(error.toString());

        reject({
          type: 'FILETRANSCODEFAILURE',
          result: false,
        });
      });
  });
};

/**
 * 视频转码
 *
 * @param {*} ffmpeg 转码器实例
 * @param {*} file  文件
 * @return {*}  返回
 */
export const videoTranscodeFunc = async (
  ffmpeg: any,
  file: any,
  name: string
) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      if (!ffmpeg.isLoaded()) await ffmpeg.load(); // load过的不需要重复load，否则会转码失败
      // console.log('1', name, `${name}.mp4`);
      console.log('ffmpegffmpegffmpeg:', ffmpeg, file);

      // ffmpeg.FS('writeFile', name, await fetchFile(file.raw));
      ffmpeg.FS('writeFile', name, await fetchFile(file));
      // console.log('2', name, `${name}.mp4`);
      ffmpeg.run('-i', name, `${name}.mp4`);
      console.log('videoTranscodeFunc成功');

      resolve(true);
    } catch (err: any) {
      throw new Error(err.toString());
      console.log('ffmpeg run error', err);
      reject(false);
    }
  });
};

export const exitFfmpeg = () => {
  console.log('退出前ffmpegInstance:', ffmpegInstance);
  if (ffmpegInstance.isLoaded()) {
    ffmpegInstance.exit();
  }
  console.log('退出后ffmpegInstance:', ffmpegInstance);
};

// 防抖
// 不管怎么点击，只在500毫秒后触发事件，500毫秒内点击也要等到500毫秒后再触发事件
export const debounce1 = (fn: Function, delay = 500) => {
  let timer: any | null = null;
  return (...args: any[]) => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      fn(...args);
    }, delay);
  };
};
